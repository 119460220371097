import React from 'react';
import { Container } from './contact.styles';
import ContactForm from '@components/contact/contact-form/contact-form.component';

const Contact = () => {
  return (
    <Container>
      <h2>Get in touch</h2>
      <ContactForm />
    </Container>
  );
};

export default Contact;
