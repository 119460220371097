const constants = {
  img: {
    src: 'https://lh3.googleusercontent.com/V7ENRFN0G2l9u4lvK_IL8sAGI-9LXnlsNyppRkXihhQfepwb92A0uztI4ecsYlgTiUEieBppV9nPyaMY5SDJUEkxbNpsyGlGdaJ-0IMveb8r6_NDoqYRo9fdUM9WFi0YJUvYTuNE79w=w2400',
    alt: '7 Shmarpuffs in different colors',
  },
  title: 'Shmarpuffs',
  text1: 'Keep your puffs in style with our customized Shmarpuffs',
  text2:
    'Get in touch to find out about our different styles, colors and personalization options',
};

export default constants;
