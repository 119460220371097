import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Container, LinksContainer } from './navigation.styles';
import constants from '@shared/constants';
// import { useSelector } from 'react-redux';
// import { signOutUser } from '@utils/firebase/firebase.utils';
// import { selectIsCartOpen } from '@store/cart/cart.selector';
// import { selectCurrentUser } from '@store/user/user.selector';
// import CartIcon from '@components/cart/cart-icon/cart-icon.component';
// import CartDropdown from '../../cart/cart.component';

const { routes } = constants;

const Navigation = () => {
  // const user = useSelector(selectCurrentUser);
  // const isCartOpen = useSelector(selectIsCartOpen);

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    window.onscroll = function () {
      const currentScrollPosition = window.scrollY;
      setVisible(
        prevScrollPosition > currentScrollPosition || currentScrollPosition < 50
      );
      prevScrollPosition = currentScrollPosition;
    };
  }, []);

  // const sighOutHandler = async () => {
  //   await signOutUser();
  // };

  return (
    <>
      <Container visible={visible}>
        <Link to={routes.home}>leibmade</Link>
        <LinksContainer>
          <Link to={routes.contact}>Contact</Link>
          <Link to={routes.extShop} target={'_blank'}>
            Shop
          </Link>
          {/*<CartIcon />*/}
        </LinksContainer>
        {/*{isCartOpen && <CartDropdown />}*/}
      </Container>
      <Outlet />
    </>
  );
};

export default Navigation;
