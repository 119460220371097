import React from 'react';
import { Container } from './not-found.styles';

const NotFound = () => {
  return (
    <Container>
      <h1>404</h1>
      <h4>Page not found</h4>
      <span>The page you are looking for does not exist</span>
    </Container>
  );
};

export default NotFound;
