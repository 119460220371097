import React from 'react';
import { Container, Text } from './cover-section.styles';

interface Props {
  title: string;
  text: string;
}

const CoverSection = ({ title, text }: Props) => {
  return (
    <Container>
      <h1>{title}</h1>
      <Text>{text}</Text>
    </Container>
  );
};

export default CoverSection;
