import { createSlice } from '@reduxjs/toolkit';
import { CartItem } from '@shared/types';

const addCartItem = (cartItems: CartItem[], itemToAdd: CartItem) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.id === itemToAdd.id && cartItem.colorName === itemToAdd.colorName
  );

  if (existingCartItem) {
    return cartItems.map((cartItem: CartItem) =>
      cartItem.id === itemToAdd.id && cartItem.colorName === itemToAdd.colorName
        ? { ...cartItem, quantity: cartItem.quantity + itemToAdd.quantity }
        : cartItem
    );
  } else {
    return [...cartItems, itemToAdd];
  }
};

const removeCartItem = (cartItems: CartItem[], itemToRemove: CartItem) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.id === itemToRemove.id &&
      cartItem.colorName === itemToRemove.colorName
  );

  if (existingCartItem && existingCartItem.quantity > 1) {
    return cartItems.map((cartItem: CartItem) =>
      cartItem.id === itemToRemove.id &&
      cartItem.colorName === itemToRemove.colorName
        ? { ...cartItem, quantity: cartItem.quantity - 1 }
        : cartItem
    );
  } else {
    return clearCartItem(cartItems, itemToRemove);
  }
};

const clearCartItem = (cartItems: CartItem[], itemToClear: CartItem) => {
  return cartItems.filter(
    (cartItem) =>
      !(
        cartItem.id === itemToClear.id &&
        cartItem.colorName === itemToClear.colorName
      )
  );
};

export interface CartState {
  readonly isCartOpen: boolean;
  readonly cartItems: CartItem[];
  readonly shakeIcon: boolean;
}

const initialState: CartState = {
  isCartOpen: false,
  cartItems: [],
  shakeIcon: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setIsCartOpen(state, action) {
      state.isCartOpen = action.payload;
    },
    setShakeIcon(state, action) {
      state.shakeIcon = action.payload;
    },
    addItemToCart(state, action) {
      state.cartItems = addCartItem(state.cartItems, action.payload);
    },
    removeItemFromCart(state, action) {
      state.cartItems = removeCartItem(state.cartItems, action.payload);
    },
    clearItemFromCart(state, action) {
      state.cartItems = clearCartItem(state.cartItems, action.payload);
    },
    clearCart(state) {
      state.cartItems = [];
    },
  },
});

export const {
  setIsCartOpen,
  setShakeIcon,
  addItemToCart,
  removeItemFromCart,
  clearItemFromCart,
  clearCart,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
