const constants = {
  routes: {
    home: '/',
    subRoute: '/*',
    termsAndConditions: '/terms-and-conditions',
    extShop: 'https://www.leibmade.shop',
    shop: '/shop',
    auth: '/auth',
    checkout: '/checkout',
    orderPlaced: '/order-placed',
    contact: '/contact',
    shmarpuffs: '/shmarpuffs',
    category: ':category',
    product: ':product',
    notFound: '*',
  },
  nis: (price: number) => `₪${price}`,
  plusPrefix: (prefix: string) => `+${prefix}`,
  flagIconAlt: (name: string) => `Flag of ${name}`,
  countries: [
    {
      id: 78,
      iso: 'IL',
      name: {
        en: 'Israel',
        he: 'ישראל',
      },
      phone_code: '972',
      thumbnail:
        'https://control-staging-storage.s3.eu-west-1.amazonaws.com/uploads/countries/ed1d6a94-4705-46d2-8dab-b36d4e80876e_IL%403x.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5EZGX7ANAPLAH4PO%2F20230906%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230906T103701Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMSJHMEUCIAMrbC8q0os86OuybfmoOKPn6P2wOLfKHax57cTWrgejAiEAmvSPtleLt671z9aOS6RQIVOckurEz0XD8mw9e6HhNtAqhQQIdBADGgw5MDM2MzUwMDc1MTQiDDFjNDFcvXmoVVG6diriA0dyrR71ESqOKmNtMdt7v5rIKf%2BLy%2FdsNWVvbLZGVeatpWQB7uErzNudpc8QFe1K8mJ4NZV1ZShZ%2FJ41HU02ffaghjE7ixgMRy36CBZwnwi%2FjX8DxpmtUkt85JRDspHgODThsudfNrK3Wj5A32obNOwra6PtBShtDldjWUuFkxJahnSN4Lz%2BswSiC20fmPfnSTDiHRTC940Fo20XpICmHy7gQ8%2FcP4dvaTM8w%2Fg%2BjTQSBRsup5fiq%2Fntoj%2FFX5mMZVJ3U6aBqrNwMT8qVdnrwYrfNIhJScETsUWHUorRRGhesTb0zi7E08Dc%2FiDNCvyhW%2B2SziPCx05O87vPF0ct0Er0T67zS%2Fj7UjGkhsn0EWvUbQczsDxpw9cqb5JzGtrU4lj6g1XWHFMgC6pp%2F%2BAcEsk1O8AxVzOh2qaZMghvZh7wy13mA%2FSMZWp9cpmw2NfWv1zfdkwA6vPHfywkOBjy7OCwwo9dD0oGKZXT4jkNE8MeHBVuGZ2k32CTFhSpPXiuPJ5BsYM6Cv2eurVGB6RM9Lt%2F4DjZMmS9JEz4D6YpY9t%2BIFfKOxcqby2FRbR%2FowtXSH5BMlBfne9wMcWwwYFoEiDzdVEPG0EncrJUASxHX4WRsq7OQfwrnZh%2BSX3xufZm4zWnMMCr4acGOqUB6qGIBNAh7s%2BwyVQ90PbpZEppEyyq0fbBSszCDuf1gVDVLmc1xuRqC837%2BPCPpwEyZHNVqMed0SdB4%2BRiXOSbmiEngUZbUhbC7L2LIqUjp6zIQVnxPHQWv3LqEJOplwGpd9%2FpDU1DHwV2wV4trTw9xJ8mBX1B2Z1yRi34t%2BdwgXfv7Yx0tw%2FDDrBzlbE4jCV59nZc0YhaVzfuSuTU7%2FXCbF0DHgit&X-Amz-Signature=bf2f18b6abf85ec0be265fc19765dd7a336d91bd4bc2a3fcffbd3babe264e8ed&X-Amz-SignedHeaders=host&x-id=GetObject',
    },
    {
      id: 181,
      iso: 'US',
      name: {
        en: 'United States',
        he: 'ארצות הברית',
      },
      phone_code: '1',
      thumbnail:
        'https://control-staging-storage.s3.eu-west-1.amazonaws.com/uploads/countries/dfa0e5a0-d15a-4dc4-9e4a-88a1c73072ad_US%403x.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5EZGX7ANAPLAH4PO%2F20230906%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230906T103701Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMSJHMEUCIAMrbC8q0os86OuybfmoOKPn6P2wOLfKHax57cTWrgejAiEAmvSPtleLt671z9aOS6RQIVOckurEz0XD8mw9e6HhNtAqhQQIdBADGgw5MDM2MzUwMDc1MTQiDDFjNDFcvXmoVVG6diriA0dyrR71ESqOKmNtMdt7v5rIKf%2BLy%2FdsNWVvbLZGVeatpWQB7uErzNudpc8QFe1K8mJ4NZV1ZShZ%2FJ41HU02ffaghjE7ixgMRy36CBZwnwi%2FjX8DxpmtUkt85JRDspHgODThsudfNrK3Wj5A32obNOwra6PtBShtDldjWUuFkxJahnSN4Lz%2BswSiC20fmPfnSTDiHRTC940Fo20XpICmHy7gQ8%2FcP4dvaTM8w%2Fg%2BjTQSBRsup5fiq%2Fntoj%2FFX5mMZVJ3U6aBqrNwMT8qVdnrwYrfNIhJScETsUWHUorRRGhesTb0zi7E08Dc%2FiDNCvyhW%2B2SziPCx05O87vPF0ct0Er0T67zS%2Fj7UjGkhsn0EWvUbQczsDxpw9cqb5JzGtrU4lj6g1XWHFMgC6pp%2F%2BAcEsk1O8AxVzOh2qaZMghvZh7wy13mA%2FSMZWp9cpmw2NfWv1zfdkwA6vPHfywkOBjy7OCwwo9dD0oGKZXT4jkNE8MeHBVuGZ2k32CTFhSpPXiuPJ5BsYM6Cv2eurVGB6RM9Lt%2F4DjZMmS9JEz4D6YpY9t%2BIFfKOxcqby2FRbR%2FowtXSH5BMlBfne9wMcWwwYFoEiDzdVEPG0EncrJUASxHX4WRsq7OQfwrnZh%2BSX3xufZm4zWnMMCr4acGOqUB6qGIBNAh7s%2BwyVQ90PbpZEppEyyq0fbBSszCDuf1gVDVLmc1xuRqC837%2BPCPpwEyZHNVqMed0SdB4%2BRiXOSbmiEngUZbUhbC7L2LIqUjp6zIQVnxPHQWv3LqEJOplwGpd9%2FpDU1DHwV2wV4trTw9xJ8mBX1B2Z1yRi34t%2BdwgXfv7Yx0tw%2FDDrBzlbE4jCV59nZc0YhaVzfuSuTU7%2FXCbF0DHgit&X-Amz-Signature=e2c7fa53f1b961475fa8ebe950b941f191be033185cfadd17d1d335ddf847718&X-Amz-SignedHeaders=host&x-id=GetObject',
    },
  ],
};

export default constants;
