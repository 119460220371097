import styled from 'styled-components';
import {
  Col,
  PageContainer,
} from '../../design-system/container/container.styles';

const Container = styled(PageContainer)`
  padding: 16px;
  gap: 32px;
  align-items: center;

  ${({ theme }) => theme.media.md} {
    padding: 32px;
  }
`;

const ContentContainer = styled(Col)`
  gap: 24px;
  align-items: center;

  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 400px 400px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled.span`
  white-space: pre-line;
  font-size: 24px;
  text-align: center;

  ${({ theme }) => theme.media.md} {
    text-align: left;
  }
`;

export { Container, ContentContainer, Image, Text };
