import styled, { css } from 'styled-components';
import { EPosition, TSize } from '@shared/types';

interface ContainerProps {
  pos?: EPosition;
  mb?: TSize;
  pad?: TSize;
  br?: TSize;
  bs?: TSize;
  bg?: string;
  c?: string;
  w?: number;
  h?: number;
  clickable?: boolean;
  border?: boolean;
}

const Container = styled.div<ContainerProps>`
  ${({
    theme,
    pos,
    mb,
    pad,
    br,
    bs,
    bg,
    onClick,
    clickable,
    c,
    w,
    h,
    border,
  }) => css`
    ${pos && `position: ${pos};`}
    ${mb && `margin-bottom: ${theme.spacing[mb]};`}
    ${pad && `padding: ${theme.spacing[pad]};`}
    ${br && `border-radius: ${theme.borderRadius[br]};`}
    ${bs && `box-shadow: ${theme.boxShadow[bs]};`}
    ${bg && `background: ${theme.colors[bg]};`}
    ${c && `color: ${theme.colors[c]};`}
    ${w && `width: ${w}rem;`}
    ${h && `height: ${h}rem;`}
    ${(onClick || clickable) && `cursor: pointer;`}
    ${border && `border: 1px solid ${theme.colors.border}`}
  `}
`;

interface FlexContainerProps {
  gap?: string | number;
  wrp?: boolean;
  aic?: boolean;
  aife?: boolean;
  jcsb?: boolean;
  jcc?: boolean;
  oxs?: boolean;
  oys?: boolean;
  fw?: boolean;
  fh?: boolean;
}

const Row = styled(Container)<FlexContainerProps>`
  display: flex;
  ${({ theme, gap, wrp, aic, aife, jcsb, jcc, oxs, oys, fw, fh }) => css`
    ${gap && `gap: ${theme.spacing[gap]};`}
    ${wrp && `flex-wrap: wrap;`}
    ${aic && `align-items: center;`}
    ${aife && `align-items: flex-end;`}
    ${jcsb && `justify-content: space-between;`}
    ${jcc && `justify-content: center;`}
    ${oxs && `overflow-x: scroll;`}
    ${oys && `overflow-y: scroll;`}
    ${fw && `width: 100%;`}
    ${fh && `height: 100%;`}
  `}
`;

const Col = styled(Row)`
  flex-direction: column;
`;

const PageContainer = styled(Row)`
  height: 100vh;
`;

const InnerPageContainer = styled(Col)`
  padding: ${({ theme }) => theme.spacing.xl};
  gap: ${({ theme }) => theme.spacing.lg};
  flex: 1;
  overflow: hidden;
  max-width: calc(100vw - 16rem);
`;

const InnerPageContainerNoInlinePadding = styled(InnerPageContainer)`
  padding: ${({ theme }) => theme.spacing.xl} 0;
`;

const ContainerInlinePadding = styled.div`
  padding-inline: ${({ theme }) => theme.spacing.xl};
`;

const TwoButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
`;

export {
  Container,
  Row,
  Col,
  PageContainer,
  InnerPageContainer,
  TwoButtonsContainer,
  InnerPageContainerNoInlinePadding,
  ContainerInlinePadding,
};
