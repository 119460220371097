import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from '@styles/container/container.styles';
import { Link, Text } from '@styles/typography/typography.styles';
import constants from '@shared/constants';

const { routes } = constants;

const Footer = () => {
  const navigate = useNavigate();

  const onClickTermsAndConditions = () => {
    navigate(routes.termsAndConditions);
  };

  return (
    <Row pad={32} c={'background'} bg={'text'} jcsb>
      <Text w={'bold'} s={28}>
        leibmade
      </Text>
      <Col>
        <Text s={18}>0544729119</Text>
        <Text s={18}>Tel Aviv, Israel</Text>
        <Link s={18} onClick={onClickTermsAndConditions}>
          Terms & Conditions
        </Link>
      </Col>
    </Row>
  );
};

export default Footer;
