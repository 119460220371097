const constants = {
  coverSection1: {
    title: 'Customize your Everyday',
    text: "At leibmade we design and print home decor, life hacks, fun stuff,\nor anything you always wanted to create but didn't know how",
  },
  coverSection2: {
    title: 'Welcome to leibmade, where creativity meets customization!',
    text: 'Unleash your imagination and personalize your everyday life with our unique collection of handmade and 3D-printed creations. We offer a hub for makers, design enthusiasts, and those who appreciate the beauty of personalized home decor.',
  },
  coverSection3: {
    title: 'Discover a World of Possibilities',
    text: 'From stunning home decor pieces to clever life hacks and everything in between, leibmade is your go-to destination for one-of-a-kind creations. Our curated selection is designed to add a touch of personality to your space and elevate your style.',
  },
  coverSection4: {
    title: 'Crafted with Precision',
    text: 'Our passion lies in the art of making. Each item in our collection is meticulously crafted and 3D-printed to perfection. We believe in the power of customization, allowing you to express your unique style through the products you choose for your home.',
  },
  coverSection5: {
    title: 'Elevate Your Home, Express Your Style',
    text: 'Transform your living spaces into a reflection of your personality. Our collection seamlessly blends form and function, ensuring that every item not only looks good but also serves a purpose. Embrace a lifestyle of creativity and make your home truly yours.',
  },
};

export default constants;
