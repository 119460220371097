import { createGlobalStyle, DefaultTheme } from 'styled-components';

interface KeyValue {
  [key: string]: string;
}

interface Theme extends DefaultTheme {
  colors: KeyValue;
  typography: KeyValue;
  media: KeyValue;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Agdasima', sans-serif;
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => (theme as Theme).colors.background};
    color: ${({ theme }) => (theme as Theme).colors.text};
  }

  a {
    font-family: inherit;
    text-decoration: none;
    color: ${({ theme }) => (theme as Theme).colors.text};
    line-height: 1.15;
  }
  
  span {
    font-family: inherit;
    color: ${({ theme }) => (theme as Theme).colors.text};
    line-height: 1.15;
    
    &.pac-matched, &.pac-item, &.pac-container, &.pac-item-query, &.pac-item-selected {
      color: ${({ theme }) => (theme as Theme).colors.background};
      font-family: inherit;
    }
  }

  div {
    &.pac-container {
      color: ${({ theme }) => (theme as Theme).colors.background};
      font-family: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Fredoka', cursive;
    font-weight: 600;
    text-align: inherit;
    color: ${({ theme }) => (theme as Theme).colors.text};
    line-height: 1.15;
    margin: 0;
    white-space: pre-wrap;
    max-width: 100vw;

    ${({ theme }) => (theme as Theme).media.lg} {
      max-width: 1000px;
    }
  }

  h1 {
    font-size: 2rem;

    ${({ theme }) => (theme as Theme).media.lg} {
      font-size: 3.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;

    ${({ theme }) => (theme as Theme).media.lg} {
      font-size: 3rem;
    }
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1rem;
  }

  * {
    box-sizing: border-box;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export default GlobalStyle;
