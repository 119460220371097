import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../../components/spinner/spinner.component';

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-family: inherit;
  ${({ theme }) => css`
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    border-radius: ${theme.borderRadius.sm};
    font-size: ${theme.typography.fontSize.md};
  `}
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  isLoading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { children, isLoading } = props;
  const content = isLoading ? <Spinner /> : children;

  return <BaseButton {...props}>{content}</BaseButton>;
};

const PrimaryButton = styled(Button)`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.border};
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    font-size: 24px;

    &:hover {
      background-color: ${theme.colors.text};
      color: ${theme.colors.background};
    }
  `}
`;

const GhostButton = styled(Button)`
  border: none;

  ${({ theme }) => css`
    color: ${theme.colors.text};
    background-color: transparent;

    &:hover {
      color: ${theme.colors.background};
      background-color: ${theme.colors.text};
    }
  `}
`;

export { PrimaryButton, GhostButton };
