import React from 'react';
import ContactForm from '@components/contact/contact-form/contact-form.component';
import { Container, ContentContainer, Image, Text } from './shmarpuffs.styles';
import constants from './shmarpuffs.constants';

const { img, title, text1, text2 } = constants;

const Shmarpuffs = () => {
  return (
    <Container>
      <h1>{title}</h1>
      <ContentContainer>
        <Image {...img} />
        <Text>{text1}</Text>
        <Text>{text2}</Text>
        <ContactForm />
      </ContentContainer>
    </Container>
  );
};

export default Shmarpuffs;
