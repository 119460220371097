import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import ReactGA from 'react-ga';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  NextOrObserver,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  DocumentReference,
  DocumentData,
} from 'firebase/firestore';
import { CategoryMap } from '../../shared/types';

const firebaseConfig = {
  apiKey: 'AIzaSyCg9HVR7HwNWDQkpIDJcrdd24H2MArOqoU',
  authDomain: 'leibmade-db.firebaseapp.com',
  projectId: 'leibmade-db',
  storageBucket: 'leibmade-db.appspot.com',
  messagingSenderId: '774927687699',
  appId: '1:774927687699:web:c6a252f70d11a7ae351fe6',
  measurementId: 'G-SJQRSNZMD4',
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
ReactGA.initialize('G-SJQRSNZMD4');

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: 'select_account',
});

export const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleAuthProvider);

export const db = getFirestore();

interface ObjectToAdd {
  category: string;
}

export const addCollectionAndDocuments = async <T extends ObjectToAdd>(
  collectionKey: string,
  objectsToAdd: T[]
): Promise<void> => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.category.toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
};

export const getProductsAndDocuments = async (): Promise<CategoryMap[]> => {
  const collectionRef = collection(db, 'products');
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(
    (docSnapshot) => docSnapshot.data() as CategoryMap
  );
};

interface AdditionalInformation {
  displayName?: string;
}

export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation = {} as AdditionalInformation
): Promise<void | DocumentReference<DocumentData>> => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log('Error creating currentUser', error);
    }

    return userDocRef as DocumentReference<DocumentData>;
  }
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = () => signOut(auth);

export const onAuthStateChangeListener = (callback: NextOrObserver<any>) => {
  return onAuthStateChanged(auth, callback);
};
