const constants = {
  to: 'leibmade@gmail.com',
  subject: (name: string, email: string) => `Contact from ${name} ${email}`,
  defaultFormFields: {
    name: '',
    email: '',
    text: '',
  },
  formFieldsProps: {
    name: {
      label: 'Name',
      type: 'text',
      required: true,
      name: 'name',
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
      name: 'email',
    },
    text: {
      label: 'Your message',
      textArea: true,
      name: 'text',
    },
  },
  buttonLabel: 'Send',
};

export default constants;
