import styled, { css } from 'styled-components';

const inputStyling = css`
  padding: 10px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  margin: 16px 0;
  font-family: inherit;

  &::placeholder {
    color: red;
  }
`;

const Input = styled.input`
  ${inputStyling};
`;

const TextArea = styled.textarea`
  ${inputStyling};
  resize: none;
`;

interface LabelProps {
  hasValue: boolean;
}

const shrinkLabel = css`
  left: 6px;
  top: -15px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
`;

const Label = styled.label<LabelProps>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.placeholder};
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 8px;
  transition: 300ms ease all;

  ${({ hasValue }) => hasValue && shrinkLabel}
`;

const Container = styled.div`
  position: relative;

  ${Input}:focus + ${Label} {
    ${shrinkLabel}
  }

  ${TextArea}:focus + ${Label} {
    ${shrinkLabel}
  }
`;

export { Container, Label, Input, TextArea };
