import React, { useEffect, useState, useRef } from 'react';
import { Container, ContentWrapper } from './scrolling-container.styles';

interface Props {
  children: React.ReactNode;
}

const ScrollingContainer = ({ children }: Props) => {
  const [yPosition, setYPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef && containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setYPosition(window.scrollY / 4);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleVisibility = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setVisible(
          rect.top !== null &&
            rect.bottom !== null &&
            rect.top < window.innerHeight &&
            rect.bottom >= 0
        );
      }
    };

    window.addEventListener('scroll', handleVisibility);
    // window.addEventListener('resize', handleVisibility);

    return () => {
      window.removeEventListener('scroll', handleVisibility);
      // window.removeEventListener('resize', handleVisibility);
    };
  });

  return (
    <Container ref={containerRef}>
      <ContentWrapper yPosition={yPosition} visible={visible}>
        {children}
      </ContentWrapper>
    </Container>
  );
};

export default ScrollingContainer;
