import styled, { css } from 'styled-components';

interface FlexContainerProps {
  gap?: number;
}

const Row = styled.div<FlexContainerProps>`
  display: flex;
  ${({ theme, gap }) => css`
    ${gap &&
    css`
      gap: ${theme.spacing[gap]};
    `}
  `}
`;

const Col = styled(Row)`
  flex-direction: column;
`;

const PageContainer = styled(Col)`
  margin-top: 88px;
  min-height: calc(100vh - 88px);
  background-color: transparent;
  padding: 32px;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.md} {
    max-width: 1200px;
    margin: 88px auto 0;
  }
`;

const Grid = styled.div`
  display: grid;
`;

interface ShakeProps {
  shake?: boolean;
}

const Shake = styled.div<ShakeProps>`
  ${({ shake }) =>
    shake &&
    css`
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
    `}

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
`;

export { PageContainer, Row, Col, Grid, Shake };
