import styled, { css } from 'styled-components';
import { TFontWeight, TSize } from '@shared/types';

interface TextProps {
  s?: TSize;
  w?: TFontWeight;
  c?: string;
  lh?: number;
  ws?: string;
  el?: boolean;
  ts?: number;
  tac?: boolean;
  ul?: boolean;
}

const Text = styled.span<TextProps>`
  max-width: 100%;
  vertical-align: middle;
  ${({ theme, s, w, c, lh, ws, el, ts, tac, ul }) => css`
    font-size: ${s
      ? theme.typography.fontSize[s]
      : theme.typography.fontSize.sm};
    font-weight: ${w
      ? theme.typography.fontWeight[w]
      : theme.typography.fontWeight.regular};
    color: ${c ? theme.colors[c] : 'inherit'};
    line-height: ${lh ?? 1.15};
    white-space: ${ws || 'normal'};
    ${el &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
    ${ts &&
    css`
      text-shadow: ${theme.textShadow[ts]};
    `}
    ${tac &&
    css`
      text-align: center;
    `}
     ${ul &&
    css`
      text-decoration: underline;
    `}
  `}
`;

const Link = styled(Text)`
  cursor: pointer;
`;

export { Text, Link };
