import styled, { css } from 'styled-components';
import { Col } from '../../../design-system/container/container.styles';

const Container = styled(Col)`
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 24px;
  white-space: pre-line;
  max-width: 100vw;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.content};
    ${theme.media.md} {
      font-size: 32px;
      max-width: 1000px;
    }
  `}
`;

export { Container, Text };
