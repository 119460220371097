import React from 'react';
import { Container, Label, Input, TextArea } from './form-input.styles';

interface Props {
  label: string;
  type?: string;
  required?: boolean;
  onChange: any;
  name: string;
  value?: string | number;
  textArea?: boolean;
  rows?: number;
}

const FormInput = ({ label, value, textArea, ...otherProps }: Props) => {
  return (
    <Container>
      {textArea ? <TextArea {...otherProps} /> : <Input {...otherProps} />}
      <Label hasValue={!!value}>{label}</Label>
    </Container>
  );
};

export default FormInput;
