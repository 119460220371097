import styled from 'styled-components';
import { Col } from '../../../design-system/container/container.styles';
import { PrimaryButton } from '../../../design-system/button/button.styles';

const Container = styled(Col)`
  width: 100%;
  max-width: 400px;

  ${PrimaryButton} {
    margin: 0 auto;
  }
`;

export { Container };
