import styled from 'styled-components';
import { Row } from '../../../design-system/container/container.styles';

interface ContainerProps {
  visible: boolean;
}

const Container = styled(Row)<ContainerProps>`
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  font-size: 24px;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.text};
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100vw;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

const LinksContainer = styled(Row)`
  gap: 16px;
  font-size: 20px;
  align-items: center;
`;

export { Container, LinksContainer };
