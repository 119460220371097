import { createSlice } from '@reduxjs/toolkit';

export interface UIState {
  toastProps: {
    show: boolean;
    message: string;
    actionText?: string;
    action?: () => void;
  };
}

const initialState: UIState = {
  toastProps: {
    show: false,
    message: '',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setToastProps: (state, action) => {
      state.toastProps = action.payload;
    },
    resetToast: (state) => {
      state.toastProps = { show: false, message: '' };
    },
  },
});

export const { setToastProps, resetToast } = uiSlice.actions;

export const uiReducer = uiSlice.reducer;
