import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import FormInput from '../../../design-system/form-input/form-input.component';
import { PrimaryButton } from '../../../design-system/button/button.styles';
import { Container } from './contact-form.styles';
import constants from './contact-form.constants';
import sendEmail from '@api/send-email';

const { to, subject, defaultFormFields, formFieldsProps, buttonLabel } =
  constants;

const ContactForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const formRef = useRef<HTMLFormElement>(null);
  const { name, email, text } = formFields;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetForm = () => {
    setFormFields(defaultFormFields);
    if (formRef.current) formRef.current.reset();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await sendEmail(to, subject(name, email), text);
      resetForm();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} ref={formRef}>
        <FormInput
          {...formFieldsProps.name}
          onChange={handleChange}
          value={name}
        />
        <FormInput
          {...formFieldsProps.email}
          onChange={handleChange}
          value={email}
        />
        <FormInput
          {...formFieldsProps.text}
          onChange={handleChange}
          value={text}
          rows={5}
        />
        <PrimaryButton type={'submit'}>{buttonLabel}</PrimaryButton>
      </form>
    </Container>
  );
};

export default ContactForm;
