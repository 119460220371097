import { combineReducers } from '@reduxjs/toolkit';
import { userReducer, UserState } from './user/user.reducer';
import { productsReducer, ProductsState } from './products/products.reducer';
import { cartReducer, CartState } from './cart/cart.reducer';
import { uiReducer, UIState } from './ui/ui.reducer';

export interface State {
  user: UserState;
  products: ProductsState;
  cart: CartState;
  ui: UIState;
}

export const rootReducer = combineReducers({
  user: userReducer,
  products: productsReducer,
  cart: cartReducer,
  ui: uiReducer,
});
