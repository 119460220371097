import { Container } from './home.styles';
import CoverSection from '@components/layout/cover-section/cover-section.component';
import constants from './home.constants';
import ScrollingContainer from '../../../design-system/container/scrolling-container/scrolling-compoent.component';
import { Row } from '../../../design-system/container/container.styles';
import { ReactComponent as A1 } from '@icons/plants/a1.svg';
import { ReactComponent as A2 } from '@icons/plants/a2.svg';
import { ReactComponent as A3 } from '@icons/plants/a3.svg';
import { ReactComponent as A4 } from '@icons/plants/a4.svg';
import { ReactComponent as B1 } from '@icons/plants/b1.svg';
import { ReactComponent as B2 } from '@icons/plants/b2.svg';
import { ReactComponent as B3 } from '@icons/plants/b3.svg';
import { ReactComponent as B4 } from '@icons/plants/b4.svg';
import { ReactComponent as C1 } from '@icons/plants/c1.svg';
import { ReactComponent as C2 } from '@icons/plants/c2.svg';
import { ReactComponent as C3 } from '@icons/plants/c3.svg';
import { ReactComponent as C4 } from '@icons/plants/c4.svg';
import { Col } from '@styles/container/container.styles';

const Home = () => {
  const components = [A1, B1, C1, A2, B2, C2, A3, B3, C3, A4, B4, C4];
  const duplicated = components
    .concat(components)
    .concat(components)
    .concat(components)
    .concat(components);
  const rotate = (array: any[], n: number) =>
    array.slice(n, array.length).concat(array.slice(0, n));

  const plantersRow = (components: any[]) => (
    <ScrollingContainer>
      <Row gap={64}>
        {components.map((Component) => (
          <Component height={64} width={64} fill={'currentColor'} />
        ))}
      </Row>
    </ScrollingContainer>
  );

  return (
    <Container>
      <Col gap={128}>
        {plantersRow(duplicated)}
        <CoverSection {...constants.coverSection2} />
        {plantersRow(rotate(duplicated, 2))}
        <CoverSection {...constants.coverSection3} />
        {plantersRow(rotate(duplicated, 4))}
        <CoverSection {...constants.coverSection4} />
        {plantersRow(rotate(duplicated, 6))}
        <CoverSection {...constants.coverSection5} />
        {plantersRow(rotate(duplicated, 8))}
      </Col>
    </Container>
  );
};

export default Home;
