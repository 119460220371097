const theme = {
  colors: {
    transparent: '#FFFFFF00',
    primary: '#67ADB4',
    secondary: '#79DEE8',
    tertiary: '#E4F8FA',
    success: '#2B9A04',
    warning: '#FF9500',
    danger: '#E26464',
    text: '#FFFFFF',
    mutedText: '#88888C',
    grayBlackText: '#2C2C2E',
    background: '#000000',
    border: '#D0D0D0',
    black: '#000000',
    white: '#FFFFFF',
    red: '#FF0000',
    blue: '#0057FF',
    lightPurple: '#EAE4F6',
    purple: '#805DC1',
    turquoise: '#F2F9FA',
    pink: '#F6D7F9',
    placeholder: '#88888C',
    shadow: 'rgba(0, 0, 0, 0.1)',
    overlay: 'rgba(0, 0, 0, 0.6)',
    gray: {
      600: '#555555',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: {
      10: '0.625rem',
      12: '0.75rem',
      14: '0.875rem',
      16: '1rem',
      18: '1.125rem',
      20: '1.25rem',
      24: '1.5rem',
      28: '1.75rem',
      32: '2rem',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      black: 900,
    },
  },
  spacing: {
    2: '0.125rem',
    4: '0.25rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    64: '4rem',
    128: '8rem',
  },
  borderRadius: {
    2: '2px',
    4: '4px',
    6: '8px',
    10: '10px',
    12: '12px',
    16: '16px',
    24: '24px',
    26: '1.625rem',
    circle: '50%',
    max: '9999px',
  },
  boxShadow: {
    1: '2px 0px 8px 2px rgba(0, 0, 0, 0.1)',
    2: '1px 1px 8px rgba(101, 101, 101, 0.3)',
    3: '0px 2px 4px rgba(221, 221, 221, 0.25)',
    4: '0px 3px 30px 0px rgba(0, 0, 0, 0.06)',
  },
  buttons: {
    primary: {
      border: 'none',
      default: {
        color: '#FFFFFF',
        backgroundColor: '#000000',
      },
      hover: {
        backgroundColor: '#222222',
      },
      disabled: {
        color: '#88888C',
        backgroundColor: '#C3C3C5',
      },
    },
    secondary: {
      border: '1px solid #000000',
      default: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
      },
      selected: {
        border: 'none',
        color: '#FFFFFF',
        backgroundColor: '#88888C',
      },
      hover: {
        backgroundColor: '#EEEEEE',
      },
      focus: {
        backgroundColor: '#EEEEEE',
      },
      disabled: {
        border: 'none',
        color: '#88888C',
        backgroundColor: '#C3C3C5',
      },
    },
    ghost: {
      border: 'none',
      default: {
        color: '#000000',
        backgroundColor: '#FFFFFF00',
      },
      hover: {},
      disabled: {
        color: '#88888C',
      },
    },
  },
  links: {
    default: {
      color: '#000000',
    },
    hover: {
      main: {
        backgroundColor: '#E8EFEF',
        icon: {
          color: '#FFFFFF',
          backgroundColor: '#000000',
        },
      },
      sub: {
        icon: {
          color: '#51A3AA',
        },
      },
    },
  },
  media: {
    sm: '@media (min-width: 1024px)',
    md: '@media (min-width: 1280px)',
    lg: '@media (min-width: 1440px)',
    xl: '@media (min-width: 1600px)',
    xxl: '@media (min-width: 1920px)',
  },
};

export default theme;
