import { createSlice } from '@reduxjs/toolkit';

interface UserData {
  createdAt: Date;
  displayName: string;
  email: string;
}

export interface UserState {
  readonly currentUser: UserData | null;
}

const initialState: UserState = {
  currentUser: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
