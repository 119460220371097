import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { Routes, Route, useLocation } from 'react-router-dom';
import {
  createUserDocumentFromAuth,
  onAuthStateChangeListener,
} from '@utils/firebase/firebase.utils';
import { setCurrentUser } from '@store/user/user.reducer';
import Navigation from '@components/layout/navigation/navigation.component';
import Home from '@components/layout/home/home.component';
import Shop from '@components/shop/shop.component';
import Authentication from '@components/auth/authentication/authentication.component';
import Checkout from '@components/checkout/checkout.component';
import Contact from '@components/contact/contact.component';
import NotFound from '@components/layout/not-found/not-found.component';
import Shmarpuffs from '@components/shmarpuffs/shmarpuffs.component';
import OrderPlaced from '@components/checkout/order-placed.component';
import Footer from '@styles/footer/footer.component';
import TermsAndConditions from '@components/terms-and-conditions/terms-and-conditions.component';
import constants from './shared/constants';

const { routes } = constants;

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const unsubscribe = onAuthStateChangeListener((user) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }
      const currentUser =
        user && (({ accessToken, email }) => ({ accessToken, email }))(user);
      dispatch(setCurrentUser(currentUser));
    });

    return unsubscribe;
  });

  return (
    <>
      <Routes>
        <Route path={routes.home} element={<Navigation />}>
          <Route index element={<Home />} />
          {/*<Route path={`${routes.shop}${routes.subRoute}`} element={<Shop />} />*/}
          {/*<Route path={routes.auth} element={<Authentication />} />*/}
          {/*<Route path={routes.checkout} element={<Checkout />} />*/}
          {/*<Route path={routes.orderPlaced} element={<OrderPlaced />} />*/}
          <Route path={routes.contact} element={<Contact />} />
          <Route path={routes.shmarpuffs} element={<Shmarpuffs />} />
          <Route path={routes.notFound} element={<NotFound />} />
          <Route
            path={routes.termsAndConditions}
            element={<TermsAndConditions />}
          />
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

export default App;
