import styled from 'styled-components';

const Container = styled.div`
  min-width: 100vw;
  overflow-x: hidden;
`;

interface ContentWrapperProps {
  yPosition: number;
  visible: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  white-space: nowrap;
  transform: ${({ yPosition, visible }) =>
    visible ? `translateX(-${yPosition / 10}%)` : 'translateX(0)'};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export { Container, ContentWrapper };
