import axios from 'axios';

const sendEmail = async (to: string, subject: string, text: string) => {
  return await axios.post('/.netlify/functions/send-email', {
    to,
    subject,
    text,
  });
};

export default sendEmail;
