import {
  applyMiddleware,
  compose,
  configureStore,
  Middleware,
} from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer, State } from './root.reducer';
import { useDispatch as useReduxDispatch } from 'react-redux';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

type ExtendedPersistConfig = PersistConfig<State> & {
  whitelist: (keyof State)[];
};

const persistConfig: ExtendedPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [process.env.NODE_ENV !== 'production' && logger].filter(
  (middleware): middleware is Middleware => Boolean(middleware)
);

const composeEnhancer =
  (process.env.NODE_ENV !== 'production' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...middlewares));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export const useDispatch = (): AppDispatch => useReduxDispatch<AppDispatch>();
